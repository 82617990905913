import React, { useRef, useState, useEffect } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Link, withRouter } from 'react-router-dom';

const Navbar = ({ history, location }) => {
  const [dropdownSelection, setDropdownSelection] = useState('tenant');
  const [searchText, setSearchText] = useState('');

  const searchBar = useRef();

  const dropClickHandler = (obj) => {
    setDropdownSelection(obj);
    setSearchText(''); 
    searchBar.current.focus();
  };

  // Reset searchText on location change (when navigating to a new page)
  useEffect(() => {
    setSearchText(''); 
  }, [location.pathname]); 

  const searchChangeHandler = (e) => {
    setSearchText(e.currentTarget.value);
    if (!e.currentTarget.value.length) {
      if (location.pathname !== '/') {
        history.replace(location.pathname); 
      }
    }
  };

  const keyChangeHandler = (e) => {
    if (e.key === "Enter" || e.key.code === '13') {
      if (searchText.length) {
        const map = {
          'tenant': 'tenantName',
          'company': 'company',
          'email': 'email'
        };

        history.push(`/tenant-overview?criteria=${map[dropdownSelection]}&search=${searchText}`);
      }
    }
  };

  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  return (
    <nav className="navbar col-lg-12 col-12 p-lg-0 fixed-top d-flex flex-row">
      <div className="navbar-menu-wrapper d-flex align-items-center justify-content-between">
        <Link to="/" className="navbar-brand brand-logo-mini align-self-center d-lg-none" href="!#" onClick={evt => evt.preventDefault()}><i className='mdi mdi-home'></i></Link>
        <Link to='/' className='supervisor-sag'>
          <img src={require("../../assets/images/webMethods.png")} alt="WebMethods Logo" />
          <span className='Brahmāstra'>Customer360&deg;</span>
        </Link>
        <ul className="navbar-nav navbar-nav-left header-links align-self-center">
          {/* Add your other navbar items here */}
        </ul>

        <div className="ml-auto search-form d-none d-md-block">
          <div className="form-group inner-text">
            <input
              type="search"
              className="form-control search-bar"
              value={searchText}
              onChange={searchChangeHandler}
              onKeyPress={keyChangeHandler}
              placeholder={`Search by ${capitalizeFirstLetter(dropdownSelection)}`}
              ref={searchBar}
            />
          </div>
          <Dropdown className='dropdown-header-inner-text-box'>
            <Dropdown.Toggle variant="btn btn-outline-secondary dropdown-toggle" id="dropdownMenuButton4">
              {dropdownSelection}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {['tenant', 'company', 'email'].map((obj) => (
                obj !== dropdownSelection && (
                  <Dropdown.Item key={obj} onClick={() => dropClickHandler(obj)}>
                    {capitalizeFirstLetter(obj)}
                  </Dropdown.Item>
                )
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <ul className="navbar-nav navbar-nav-right">
        </ul>
      </div>
    </nav>
  );
};

export default withRouter(Navbar);
